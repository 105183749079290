export let recipes_test_data = [
  {
    recipe1: {
      id: 1,
      name: "Pasta med kødsovs",
      Description:
        "Step 1: Skær løg og grønt \nStep 2: Svits grøntsagerne og tilsæt kødet",
      Time: "45 min",
      Labels: [{ name: "Oksekød" }, { name: "Pasta ret" }],
      Finished_Recipe_Weight: "1kg",
      Recommended_Person_Amount: 4,
      Image: "https://www.valdemarsro.dk/wp-content/2015/09/bolognese-1.jpg",
      Ingredients: [
        {
          storeName: "REMA",
          ItemsSelected: [
            {
              name: "Hakket oksekød",
              weight: "500",
              unit: "g",
            },
          ],
        },
      ],
      price: 120,
    },
    recipe2: {
      id: 2,
      name: "Taco Beef",
      Description: "Step 1: Skær løg \nStep 2: Svits kødet",
      Time: "45 min",
      Labels: [{ name: "Oksekød" }],
      Finished_Recipe_Weight: "0.5kg",
      Recommended_Person_Amount: 4,
      Image:
        "https://www.onceuponachef.com/images/2023/08/Beef-Tacos-760x570.jpg",
      Ingredients: [
        {
          storeName: "REMA",
          ItemsSelected: [
            {
              name: "Hakket oksekød",
              weight: "500",
              unit: "g",
            },
          ],
        },
      ],
      price: 150,
    },
    recipe3: {
      id: 3,
      name: "Kødboller i peberfrugtsauce",
      Description:
        "step 1. Rengør grøntsagerne og skær dem i grove stykker. step 2. Opvarm lidt olie i en gryde og svits grøntsagerne godt af.",
      Time: "45 min",
      Labels: [{ name: "Oksekød" }],
      Finished_Recipe_Weight: "1kg",
      Recommended_Person_Amount: 4,
      Image:
        "https://sundpaabudget.dk/wp-content/uploads/2024/04/PSX_20240416_131320-750x500.jpg",
      Ingredients: [
        {
          storeName: "REMA",
          ItemsSelected: [
            {
              name: "Hakket oksekød",
              weight: "500",
              unit: "g",
            },
          ],
        },
      ],
      price: 100,
    },
    recipe4: {
      id: 4,
      name: "Pasta med kødsovs",
      Description:
        "Step 1: Skær løg og grønt \nStep 2: Svits grøntsagerne og tilsæt kødet",
      Time: "45 min",
      Labels: [{ name: "Oksekød" }, { name: "Pasta ret" }],
      Finished_Recipe_Weight: "1kg",
      Recommended_Person_Amount: 4,
      Image: "https://www.valdemarsro.dk/wp-content/2015/09/bolognese-1.jpg",
      Ingredients: [
        {
          storeName: "REMA",
          ItemsSelected: [
            {
              name: "Hakket oksekød",
              weight: "500",
              unit: "g",
            },
          ],
        },
      ],
      price: 120,
    },
    recipe5: {
      id: 5,
      name: "Pasta med kødsovs",
      Description:
        "Step 1: Skær løg og grønt \nStep 2: Svits grøntsagerne og tilsæt kødet",
      Time: "45 min",
      Labels: [{ name: "Oksekød" }, { name: "Pasta ret" }],
      Finished_Recipe_Weight: "1kg",
      Recommended_Person_Amount: 4,
      Image: "https://www.valdemarsro.dk/wp-content/2015/09/bolognese-1.jpg",
      Ingredients: [
        {
          storeName: "REMA",
          ItemsSelected: [
            {
              name: "Hakket oksekød",
              weight: "500",
              unit: "g",
            },
          ],
        },
      ],
      price: 120,
    },
  },
];

export function RecipeCard() {
  return (
    <div id="recipes" className="flex flex-row flex-wrap">
      {recipes_test_data.map((item, index) =>
        Object.keys(item).map((key) => (
          <div key={index}>
            <div
              id="recipe_card"
              className="flex flex-col justify-between max-w-md max-h-md shadow-even shadow-darkBackground rounded-lg m-5"
            >
              <img
                src={item[key].Image}
                className="object-cover rounded-t-lg h-64 w-80"
              ></img>

              <div className="flex flex-row mt-1">
                {item[key].Labels.map((labelIndex) => (
                  <span
                    key={labelIndex}
                    id="recipe_categories"
                    className="bg-darkgreyBackground font-bold m-2 p-2 rounded-full text-xs"
                  >
                    {labelIndex.name}
                  </span>
                ))}
              </div>

              <div id="titel_and_price" className="flex flex-row">
                <p className="flex text-darkText text-lg pl-2 font-bold">
                  {item[key].name}
                </p>
                <div
                  id="price"
                  className="flex text-darkText ml-auto pr-2 font-bold items-center space-x-1"
                >
                  <p>{item[key].price}</p>
                  <p>kr</p>
                </div>
              </div>
              <div id="recipe_description" className="flex flex-row pb-2">
                <div id="time" className="flex flex-row items-center px-2">
                  <img
                    src="./icon/recipes_page/time.png"
                    alt="time"
                    className="flex size-5"
                  ></img>
                  <p className="flex text-darkgreyText">{item[key].Time}</p>
                </div>
                <div
                  id="people"
                  className="flex flex-row space-x-1 ml-auto text-darkgreyText"
                >
                  <p>{item[key].Recommended_Person_Amount}</p>
                  <p>personer</p>
                </div>
                <div
                  id="price_per_person"
                  className="flex px-2 pl-5 text-darkgreyText items-center"
                >
                  <img
                    src="./icon/recipes_page/person.png"
                    alt="person"
                    className="flex size-5"
                  ></img>
                  <div
                    id="price_per_person_text"
                    className="flex flex-row space-x-1"
                  >
                    <p>
                      {item[key].price / item[key].Recommended_Person_Amount}
                    </p>
                    <p>kr</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
