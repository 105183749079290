import { signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SwiftCartLogo } from "../../components/SwiftCartLogo";
import useAuth from "../../hooks/useAuth";
import { auth } from "../../utils/firebaseSetup";

export function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const user = useAuth();

  // if user exist, they can not redirect to login page by changing URL path
  useEffect(() => {
    if (user) {
      navigate("/shoppingList");
    }
  }, [user]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function loginUser() {
    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        console.log("starting login");
        const loggedInUser = userCredential.user;
        console.log("success");
        console.log(loggedInUser);

        loggedInUser.getIdToken().then((token) => {
          console.log("acquiring token");
          localStorage.setItem("token", token);
          console.log("navigating to new page");
        });

        navigate("/shoppingList");
      })

      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log("errorCode:", errorCode, "errormessage:", errorMessage);
      });
  }

  return (
    <div className="flex flex-col w-screen h-screen bg-secondary overflow-hidden">
      <SwiftCartLogo />
      <div className="flex justify-center items-center ml-10">
        <div className="flex flex-col shadow-action shadow-even bg-darkBackground py-16 px-28 rounded-lg">
          <h1 className="flex text-lightText items-center text-4xl mb-10">
            Log ind
          </h1>
          <input
            className="shadow-action shadow-even text-lightText bg-darkBackground px-5 py-4 mt-5 rounded-lg"
            id="usernameInput"
            onChange={handleUsernameChange}
            type="email"
            value={username}
            placeholder="Email"
          ></input>
          <input
            className="shadow-action shadow-even text-lightText bg-darkBackground px-5 py-4 my-10 rounded-lg"
            id="passwordInput"
            onChange={handlePasswordChange}
            type="password"
            value={password}
            placeholder="Password"
          ></input>
          <button
            className="shadow-secondaryHover shadow-even text-lightText bg-action hover:bg-actionHover px-4 py-3 mb-5 rounded-lg"
            onClick={loginUser}
          >
            Login
          </button>
          <div className="flex flex-row space-x-1">
            <p className="text-lightText">Har du ikke en konto?</p>
            <a href="/register" className="text-blue-600 underline">
              Opret her
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
