import { useEffect, useRef, useState } from "react";
import SearchBar from "../../components/SearchBar";
import { useShoppingList } from "../../components/ShoppingList";
import { ShoppingListItemComponent } from "../../components/ShoppingListItem";
import { StorePriceComponent } from "../../components/StorePriceComponent";
import { AvailableStoresList } from "../../constant/availableStores";
import { ItemGroup } from "../../model/ItemGroup";
import { getItemGroups } from "../../services/apiService";
import { createItemGroupWithDesiredWeight } from "../../utils/createItemGroupWithDesiredWeight";

export function ShoppingList() {
  const { shoppingList, addItem, isSomethingMarked, clearList, totalPrice } =
    useShoppingList();
  const [desiredWeights, setDesiredWeights] = useState<{
    [key: string]: number | undefined;
  }>({});
  const [markedStore, setMarkedStore] = useState("Rema");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [itemGroups, setItemGroups] = useState<ItemGroup[]>([]); // Stores the fetched item groups
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown open state
  const searchBarRef = useRef<HTMLDivElement>(null);

  function addShoppingItem(itemGroup: ItemGroup, itemGroupIndex) {
    const weightForItemGroup = desiredWeights[itemGroupIndex];
    console.log(itemGroup);
    const ItemGroupWithDesiredWeight = createItemGroupWithDesiredWeight(
      weightForItemGroup,
      itemGroup.RecommendedItem.unitsOfMeasure,
      itemGroup
    );
    if (ItemGroupWithDesiredWeight.cheapestItemsForEachStore.length === 0) {
      return;
    }
    ItemGroupWithDesiredWeight.cheapestItemsForEachStore.forEach(
      (CheapestItemCombinationForStore, index) => {
        if (CheapestItemCombinationForStore.itemsSelected === null) {
          alert(
            `${itemGroup.name} is not available in ${AvailableStoresList[index]}`
          );
          return;
        }
      }
    );

    addItem(ItemGroupWithDesiredWeight);
    desiredWeights[itemGroupIndex] = undefined;
  }

  function handleDesiredWeightChange(
    event: React.ChangeEvent<HTMLInputElement>,
    groupId: number
  ) {
    const value = parseFloat(event.target.value); // Convert the input value to a number
    setDesiredWeights((prevWeights) => ({
      ...prevWeights,
      [groupId]: isNaN(value) ? undefined : value, // Update the specific group's weight
    }));
  }

  useEffect(() => {
    const fetchItemGroups = async () => {
      if (searchTerm.trim() === "") {
        setItemGroups([]);
        setIsDropdownOpen(false); // Close dropdown when searchTerm is cleared
        return;
      }

      try {
        const data = await getItemGroups(searchTerm);
        setItemGroups(data);
        setIsDropdownOpen(true); // Open dropdown when results are available
      } catch (err: any) {
        console.error("Failed to fetch item groups.");
        setIsDropdownOpen(false); // Ensure dropdown closes if an error occurs
      }
    };

    const debounceTimeout = setTimeout(() => {
      fetchItemGroups();
    }, 500); // Debounce the API call by 500ms

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false); // Close dropdown if clicked outside
      }
    };

    // Attach the event listener to detect outside clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col pl-16 bg-lightBackground text-ellipsis whitespace-nowrap w-full h-full justify-between px-10">
      <div className="flex flex-row items-center justify-between w-11/12">
        <h1 className="text-4xl text-darkText mb-10 mt-7 mr-10">
          Indkøbsliste
        </h1>

        <div className="relative flex-grow" ref={searchBarRef}>
          <SearchBar value={searchTerm} onChange={setSearchTerm} />
          {isDropdownOpen && itemGroups.length > 0 && (
            <ul className="absolute  left-0 top-full mt-2 w-full bg-white border border-gray-300 rounded-lg max-h-80 overflow-y-auto shadow-lg z-10">
              {itemGroups.map((group, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-gray-200 cursor-pointer flex flex-row justify-between items-center"
                >
                  <img
                    src={group.RecommendedItem.image}
                    className="w-1/12 m-1"
                  />
                  <p className="w-20 truncate flex flex-grow min-w-20 text-darkText">
                    {group.name}
                  </p>
                  <input
                    placeholder="Mængde"
                    className="flex w-2/12 p-2 ml-1 border rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-action"
                    onChange={(e) => handleDesiredWeightChange(e, index)}
                    value={desiredWeights[index] || ""} // Use the desired weight specific to this group
                  />
                  <p className="flex min-w-fit mx-2 py-2 px-4 border rounded-lg text-lg bg-lightgreyBackground">
                    {group.RecommendedItem.unitsOfMeasure}
                  </p>
                  <button
                    className="flex justify-center items-center bg-action w-10 h-10 rounded-lg ml-2 text-3xl font-bold hover:bg-actionHover"
                    onClick={() => {
                      console.log("Selected item group:", group.name);
                      addShoppingItem(group, index);
                      setIsDropdownOpen(false);
                    }}
                  >
                    +
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div
        id="shopping_list"
        className="flex flex-col relative top-0 w-full border-t border-two text-darkText"
      >
        <div className="overflow-y-scroll max-h-96 flex flex-col w-full">
          {shoppingList.length === 0 && (
            <p>Der er ikke tilføjet nogle vare endnu</p>
          )}

          {shoppingList.map((item) => (
            <ShoppingListItemComponent
              key={item.itemGroup.id}
              shoppingListItem={item}
              choosenStore={markedStore}
            />
          ))}
        </div>
        <div className="w-full flex justify-end">
          {isSomethingMarked() === true && (
            <button
              className="bg-cancel text-darkText w-14 h-14 rounded-full flex justify-center items-center p-2 m-2"
              onClick={() => {
                clearList();
              }}
            >
              <img src="./icon/delete.png" />
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row my-5 justify-between mt-auto sticky bottom-0 p-4 w-full border-t-2 border-lightgreyBackground">
        <div className="flex flex-row space-x-3">
          {AvailableStoresList.map((store, index) => (
            <StorePriceComponent
              key={store}
              store={store}
              clickable={true}
              onSelectStore={setMarkedStore}
              isSelected={markedStore === store}
            />
          ))}
        </div>

        <div className="flex bg-secondary items-center space-x-2 p-2 rounded-full w-fit h-14">
          <p className="text-3xl p-3 font-bold text-darkText">
            {totalPrice[0] / 100} kr
          </p>
        </div>
      </div>
    </div>
  );
}

export default ShoppingList;
