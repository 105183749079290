import { RecipeCard } from "../../components/recipes/RecipeCard";
import { RecipeCategoryButton } from "../../components/recipes/RecipeCategoryButton";

export function Recipe() {
  return (
    <div className="flex flex-col p-10 w-full">
      <div className="flex flex-row w-full">
        <div id="recipe_header" className="flex space-x-5 ">
          <button className="relative h-12 text-lg shadow-md shadow-darkgreyBackground text-darkText font-bold bg-action px-4 py-2 rounded-md">
            ALLE OPSKRIFTER
          </button>
        </div>
        <div className="flex ml-auto">
          <p>Søgefelt her</p>
        </div>
      </div>
      <div id="recipe_filter_categories" className="flex pt-5 space-x-3">
        <RecipeCategoryButton>Vegansk</RecipeCategoryButton>
        <RecipeCategoryButton>Oksekød</RecipeCategoryButton>
        <RecipeCategoryButton>Kylling</RecipeCategoryButton>
        <RecipeCategoryButton>Fisk</RecipeCategoryButton>
      </div>
      <RecipeCard></RecipeCard>
    </div>
  );
}
