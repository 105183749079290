import { createUserWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SwiftCartLogo } from "../../components/SwiftCartLogo";
import useAuth from "../../hooks/useAuth";
import { auth } from "../../utils/firebaseSetup";

export function Register() {
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const navigate = useNavigate();

  const user = useAuth();

  // if user exist, they can not redirect to register page by changing URL path
  useEffect(() => {
    if (user) {
      navigate("/shoppingList");
    }
  });

  const handleRegisterUsernameChange = (event) => {
    setRegisterUsername(event.target.value);
  };
  const handleRegisterPasswordChange = (event) => {
    setRegisterPassword(event.target.value);
  };

  function registerUser() {
    createUserWithEmailAndPassword(auth, registerUsername, registerPassword)
      .then((userCredentiel) => {
        const user = userCredentiel.user;
        console.log("succes register: ", user);
        console.log("username: ", registerUsername);

        user.getIdToken().then((token) => {
          console.log("acquiring token");
          localStorage.setItem("token", token);
          console.log("navigating to new page");
        });

        navigate("/shoppingList");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log("errorCode:", errorCode, "errormessage:", errorMessage);
      });
  }

  return (
    <div className="flex flex-col w-screen h-screen bg-secondary overflow-hidden">
      <SwiftCartLogo />
      <div className="flex justify-center items-center ml-10">
        <div className="flex flex-col shadow-action shadow-even bg-darkBackground py-16 px-28 rounded-lg">
          <h1 className="flex text-lightText items-center text-4xl mb-10">
            Opret konto
          </h1>
          <input
            className="shadow-action shadow-even text-lightText bg-darkBackground px-5 py-4 mt-5 rounded-lg"
            id="usernameInput"
            onChange={handleRegisterUsernameChange}
            type="email"
            value={registerUsername}
            placeholder="Email"
          ></input>
          <input
            className="shadow-action shadow-even text-lightText bg-darkBackground px-5 py-4 my-10 rounded-lg"
            id="passwordInput"
            onChange={handleRegisterPasswordChange}
            type="password"
            value={registerPassword}
            placeholder="Password"
          ></input>
          <button
            className="shadow-secondaryHover shadow-even text-lightText bg-action hover:bg-actionHover px-4 py-3 mb-5 rounded-lg"
            onClick={registerUser}
          >
            Registrer
          </button>
        </div>
      </div>
    </div>
  );
}
