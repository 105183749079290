import { AvailableStoresList } from "../constant/availableStores";
import { useShoppingList } from "./ShoppingList";

const selectedColor = "bg-action";
const defaultColor = "bg-secondary";

export function StorePriceComponent({
  store,
  clickable,
  onSelectStore,
  isSelected,
}: {
  store: string;
  clickable: boolean;
  onSelectStore: (storeName: string) => void;
  isSelected: boolean; // New prop to indicate if this store is selected
}) {
  const buttonColor = isSelected ? selectedColor : defaultColor; // Set color based on selected state
  const { totalPrice } = useShoppingList();
  const chosenStoreIndex = AvailableStoresList.findIndex((availableStores) => {
    return availableStores === store;
  });

  function handleColorChange() {
    if (clickable) {
      onSelectStore(store); // Notify the parent component about the selected store
    }
  }

  return (
    <div
      className={`flex ${buttonColor} items-center space-x-2 p-2 rounded-full w-fit h-16 shadow-lg ${
        clickable ? "cursor-pointer hover:bg-secondaryHover" : ""
      }`}
      onClick={clickable ? handleColorChange : undefined}
      style={{
        pointerEvents: clickable ? "auto" : "none",
      }}
    >
      <img className="rounded-full w-fit h-full" src={`./img/${store}.png`} />
      <p className="font-bold text-2xl w-fit p-2">
        {totalPrice[chosenStoreIndex] / 100} kr
      </p>
    </div>
  );
}
