import { onAuthStateChanged, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../utils/firebaseSetup";

const useAuth = () => {
  // user can be of either type user from firebase or null
  const [user, setUser] = useState<User | null>(null);

  // effect checks for wether user state has changed or not
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log("auth state changed: ", currentUser);
    });

    return () => unsubscribe();
  }, [auth]);

  return user;
};

export default useAuth;
