import React from "react";
import { ItemGroup } from "../model/ItemGroup";

interface ItemGroupCardProps {
  itemGroup: ItemGroup;
}

const ItemGroupCard: React.FC<ItemGroupCardProps> = ({ itemGroup }) => {
  const { RecommendedItem, name } = itemGroup;
  const imageUrl = RecommendedItem.image || "https://via.placeholder.com/300";
  const price =
    RecommendedItem.discountPrice || RecommendedItem.normalPrice || 0;

  const capitalizeWords = (str: string) => {
    return str.replace(/(^|\s)(\p{L})/gu, (match, p1, p2) => {
      return p1 + p2.toLocaleUpperCase("da-DK");
    });
  };

  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-even flex flex-col h-full">
      <div className="flex-1 relative">
        <img src={imageUrl} alt={name} className="w-full h-48 object-contain" />
      </div>
      <div className="p-2 flex justify-between items-center">
        <span className="font-semibold text-sm">{capitalizeWords(name)}</span>
        <span className="font-semibold text-sm text-gray">
          {(price / 100).toFixed(2)} DKK
        </span>
      </div>
    </div>
  );
};

export default ItemGroupCard;
