export function Unauthorized() {
  return (
    <div className="flex justify-center items-center w-full h-full bg-secondary">
      <div className="flex flex-col shadow-action shadow-even bg-darkBackground py-10 px-20 justify-center items-center rounded-lg">
        <div className="flex flex-row items-center m-8">
          <img src="./icon/block.png" className="size-10"></img>
          <h1 className="text-4xl text-lightText">Unauthorized</h1>
        </div>
        <div className="flex flex-col space-x-1">
          <p className="text-lightText text-lg">Du er ikke logget ind</p>

          <a
            href="/"
            className="flex justify-center items-center shadow-secondaryHover shadow-even text-lightText bg-action hover:bg-actionHover m-3 p-3 rounded-lg text-lg"
          >
            Tilbage til log ind
          </a>
        </div>
      </div>
    </div>
  );
}
