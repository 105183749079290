import { ItemGroup } from "../model/ItemGroup";
import axiosInstance from "./axiosInstance";

export async function getItemGroups(name: string): Promise<ItemGroup[]> {
  try {
    const response = await axiosInstance.get<ItemGroup[]>(
      `getitemgroups/${name}`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting itemgroups", error);
    throw error;
  }
}
