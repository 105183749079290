import { AvailableStoresList } from "../constant/availableStores";
import { ShoppingListItemProps } from "../model/ShoppingListItemProps";
import { useShoppingList } from "./ShoppingList";

export function ShoppingListItemComponent({
  shoppingListItem,
  choosenStore,
}: ShoppingListItemProps) {
  const chosenStoreIndex = AvailableStoresList.findIndex((store) => {
    return store === choosenStore;
  });
  const { setMarkedItem } = useShoppingList();

  console.log("chosenStoreIndex:", chosenStoreIndex);
  return (
    <div
      id="shopping_list_item"
      className="flex flex-row w-full h-16 items-center px-7 p-2 border-b border-lightgreyBackground"
    >
      <input
        type="checkbox"
        checked={shoppingListItem.marked}
        className="w-8 h-8 mr-5"
        onChange={() => setMarkedItem(shoppingListItem.itemGroup.id)}
      />
      <div className="flex basis-1/4 flex-grow flex-col min-w-1/6">
        <p className="font-bold text-xl">
          {shoppingListItem?.itemGroup?.name || "Unnamed Item"}
        </p>
        <p className="text-lightgreytxt">
          {shoppingListItem.desiredWeight}
          {shoppingListItem.itemGroup.RecommendedItem.unitsOfMeasure}
        </p>
      </div>
      <p className="pr-5 w-fit text-xl">{choosenStore}</p>
      {shoppingListItem.cheapestItemsForEachStore.length > 0 &&
        shoppingListItem.cheapestItemsForEachStore[
          chosenStoreIndex
        ]?.itemsSelected
          ?.filter((chosenItem, index) => {
            // Check if we are at the last item, or the current item is not the same as the next one
            return (
              index ===
                shoppingListItem.cheapestItemsForEachStore[chosenStoreIndex]
                  .itemsSelected.length -
                  1 ||
              chosenItem.name !==
                shoppingListItem.cheapestItemsForEachStore[chosenStoreIndex]
                  .itemsSelected[index + 1]?.name
            );
          })
          .map((chosenItem, index) => {
            const itemsSelected =
              shoppingListItem.cheapestItemsForEachStore[chosenStoreIndex]
                ?.itemsSelected;

            return itemsSelected && itemsSelected.length > 0 ? (
              // Render this block if itemsSelected has items
              <div
                key={index}
                className="flex flex-row h-full w-5/12 bg-lightgreyBackground hover:bg-lightgreyBackgroundHover rounded-full p-2 m-4 space-x-4 align items-center shadow-lg"
              >
                <img
                  className="w-1/6 h-full pl-3 max-w-14 shadow-lg"
                  src={chosenItem.image || "default-image-path.jpg"}
                />
                <div className="flex flex-grow flex-col overflow-hidden">
                  <p className="font-bold text-darkBackground">
                    {chosenItem.name}
                  </p>
                  <p>
                    {chosenItem.units} {chosenItem.unitsOfMeasure}
                  </p>
                </div>
                <div>
                  <p className="text-darkBackground">
                    {(chosenItem.discountPrice / 100).toFixed(2)} kr
                  </p>
                </div>
                <div className="flex bg-darkgreyBackground w-10 h-10 rounded-full justify-center items-center">
                  <p className="font-bold text-darkgreytxt p-4">
                    {itemsSelected.length}
                  </p>
                </div>
              </div>
            ) : (
              // Render this block if itemsSelected is null or empty
              <div
                key={index}
                className="flex flex-row h-full w-5/12 bg-lightgreyBackground hover:bg-lightgreyBackgroundHover rounded-full p-2 m-4 space-x-4 align items-center shadow-lg justify-center"
              >
                <p className="w-full text-darkBackground font-bold text-center">
                  No items in {choosenStore}
                </p>
              </div>
            );
          })}

      <div className="flex h-full w-fit px-4 bg-secondary rounded-full justify-center items-center">
        <p className="font-bold text-xl text-darkBackground">
          {(shoppingListItem.cheapestItemsForEachStore[0].price / 100).toFixed(
            2
          )}{" "}
          kr
        </p>
      </div>
    </div>
  );
}
