import { CheapestItemCombinationForStore } from "../model/CheapestItemCombinationForStore";
import { ItemGroup } from "../model/ItemGroup";
import { ItemGroupWithDesiredWeight } from "../model/ItemGroupWithDesiredWeight";
import { findCheapestCombination } from "./algorithms/priceCalculations";

export function createItemGroupWithDesiredWeight(
  desiredWeight: number,
  desiredUnit: string,
  itemGroup: ItemGroup
): ItemGroupWithDesiredWeight {
  let ItemGroupWithDesiredWeight: ItemGroupWithDesiredWeight = {
    itemGroup: itemGroup,
    desiredWeight: desiredWeight,
    cheapestItemsForEachStore: [],
    marked: false,
  };

  //Add cheapest item combinations for each store
  const stores = ["Rema", "Bilka"];

  stores.forEach((store) => {
    let itemCombinationForStore: CheapestItemCombinationForStore;
    itemCombinationForStore = findCheapestCombination(
      itemGroup.itemList,
      desiredWeight,
      store,
      desiredUnit
    );
    console.log(itemCombinationForStore);
    ItemGroupWithDesiredWeight.cheapestItemsForEachStore.push(
      itemCombinationForStore
    );
  });

  return ItemGroupWithDesiredWeight;
}
