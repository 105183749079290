import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { AvailableStoresList } from "../constant/availableStores";
import { ItemGroupWithDesiredWeight } from "../model/ItemGroupWithDesiredWeight";

// Define the context's structure
interface ShoppingListContextType {
  shoppingList: ItemGroupWithDesiredWeight[];
  addItem: (item: ItemGroupWithDesiredWeight) => void;
  removeItem: (itemId: number) => void;
  clearList: () => void;
  isSomethingMarked: () => boolean;
  setMarkedItem: (itemId: number) => void;
  totalPrice: number[];
}

// Create the context with default values
const ShoppingListContext = createContext<ShoppingListContextType | undefined>(
  undefined
);

export const ShoppingListProvider = ({ children }: { children: ReactNode }) => {
  const [shoppingList, setShoppingList] = useState<
    ItemGroupWithDesiredWeight[]
  >([]);
  const [totalPrice, setTotalPrice] = useState<number[]>([]);

  // Automatically assign an ID when adding an item
  const addItem = (item: ItemGroupWithDesiredWeight) => {
    console.log("first: ", item);
    setShoppingList((prev) => [...prev, item]);
    let localTotalPrice = [];
    totalPrice.forEach((price, index) => {
      if (price === undefined || price === null) {
        localTotalPrice[index] = 0;
      }
    });
    setTotalPrice(localTotalPrice);
  };

  useEffect(() => {
    calculateTotalPriceOfShoppinglist();
  }, [shoppingList]);

  const removeItem = (itemId: number) => {
    setShoppingList((prev) =>
      prev.filter((item) => item.itemGroup.id !== itemId)
    );
  };

  const clearList = () => {
    shoppingList.forEach((item) => {
      if (item.marked) {
        removeItem(item.itemGroup.id);
      }
    });
  };

  const setMarkedItem = (itemId: number) => {
    setShoppingList((prev) =>
      prev.map((item) => {
        if (item.itemGroup.id === itemId) {
          return { ...item, marked: !item.marked };
        }
        return item;
      })
    );
  };

  const isSomethingMarked = () => {
    shoppingList.forEach((item) => {
      if (item.marked) {
        return true;
      }
    });
    return false;
  };

  function calculateTotalPriceOfShoppinglist() {
    let localTotalPrice = [];
    if (shoppingList.length === 0) {
      AvailableStoresList.forEach((store, index) => {
        if (localTotalPrice[index] === undefined) {
          localTotalPrice[index] = 0;
        }
      });
      return;
    }
    console.log("shoppinglist: ", shoppingList);
    console.log(
      "cheapestItemsForEachStore: ",
      shoppingList[0].cheapestItemsForEachStore
    );

    for (
      let shoppingListItem = 0;
      shoppingListItem < shoppingList.length;
      shoppingListItem++
    ) {
      console.log(
        "length:",
        shoppingList[shoppingListItem].cheapestItemsForEachStore.length
      );

      for (
        let shopIndex = 0;
        shopIndex <
        shoppingList[shoppingListItem].cheapestItemsForEachStore.length;
        shopIndex++
      ) {
        if (
          shoppingList[shoppingListItem].cheapestItemsForEachStore[shopIndex]
            .itemsSelected === null
        ) {
          continue;
        }
        if (localTotalPrice[shopIndex] === undefined) {
          localTotalPrice[shopIndex] = 0;
        }
        localTotalPrice[shopIndex] =
          localTotalPrice[shopIndex] +
          shoppingList[shoppingListItem].cheapestItemsForEachStore[shopIndex]
            .price;
        console.log("localTotalPrice[shopIndex]: ", localTotalPrice[shopIndex]);
      }
    }

    setTotalPrice(localTotalPrice);
  }

  return (
    <ShoppingListContext.Provider
      value={{
        shoppingList,
        addItem,
        removeItem,
        clearList,
        setMarkedItem,
        isSomethingMarked,
        totalPrice,
      }}
    >
      {children}
    </ShoppingListContext.Provider>
  );
};

// Custom hook for consuming the context
export const useShoppingList = () => {
  const context = useContext(ShoppingListContext);
  if (!context) {
    throw new Error(
      "useShoppingList must be used within a ShoppingListProvider"
    );
  }
  return context;
};
