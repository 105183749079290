import React, { useEffect, useState } from "react";
import ItemGroupSection from "../../components/ItemGroupSection";
import SearchBar from "../../components/SearchBar";
import { ItemGroup } from "../../model/ItemGroup";
import { getItemGroups } from "../../services/apiService";

const ItemGroupsPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [itemGroups, setItemGroups] = useState<ItemGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchItemGroups = async () => {
      if (searchTerm.trim() === "") {
        setItemGroups([]);
        return;
      }

      setLoading(true);
      setError("");
      try {
        const data = await getItemGroups(searchTerm);
        setItemGroups(data);
      } catch (err: any) {
        setError("Failed to fetch item groups.");
      } finally {
        setLoading(false);
      }
    };

    const debounceTimeout = setTimeout(() => {
      fetchItemGroups();
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);

  // Group item groups by section
  const groupedItemGroups = itemGroups.reduce<{
    [section: string]: ItemGroup[];
  }>((groups, itemGroup) => {
    const section = itemGroup.section || "Other";
    if (!groups[section]) {
      groups[section] = [];
    }
    groups[section].push(itemGroup);
    return groups;
  }, {});

  return (
    <div className="min-h-screen max-w-6xl mx-auto p-4 text-darkText">
      <SearchBar value={searchTerm} onChange={setSearchTerm} />

      {loading && <p className="text-center mt-4 text-darkText">Loading...</p>}
      {error && <p className="text-center mt-4 text-red-500">{error}</p>}

      {!loading && !error && Object.keys(groupedItemGroups).length === 0 && (
        <p className="text-center mt-4 text-darkText">No item groups found.</p>
      )}

      {!loading && !error && (
        <div className="flex flex-col mt-6">
          {Object.keys(groupedItemGroups).map((section) => (
            <ItemGroupSection
              key={section}
              section={section}
              itemGroups={groupedItemGroups[section]}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ItemGroupsPage;
