import { CheapestItemCombinationForStore } from "../../model/CheapestItemCombinationForStore";
import { Item } from "../../model/Item";

function convertToGrams(units: number, unitsOfMeasure: string): number {
  switch (unitsOfMeasure.toLowerCase()) {
    case "g":
      return units;
    case "kg":
      return units * 1000;
    case "ml":
      return units;
    case "cl":
      return units * 10;
    case "dl":
      return units * 100;
    case "l":
      return units * 1000;
    default:
      return null;
  }
}

export function findCheapestCombination(
  items: Item[],
  desiredWeight: number,
  store: string,
  unitsOfMeasure: string
): CheapestItemCombinationForStore {
  const itemsInStore = items.filter(
    (item) => item.storeName.toLowerCase() === store.toLowerCase()
  );

  if (itemsInStore.length === 0) {
    return createNullCheapestCombination(store);
  }

  // Convert desired weight to grams
  const desiredWeightInGrams = convertToGrams(desiredWeight, unitsOfMeasure);
  if (desiredWeightInGrams === null) {
    return createNullCheapestCombination(store);
  }

  // Convert all item units to grams
  const processedItems = itemsInStore.map((item) => {
    const itemWeightInGrams = convertToGrams(item.units, item.unitsOfMeasure);
    return { ...item, itemWeightInGrams };
  });

  // Sort items by total price in ascending order
  processedItems.sort((a, b) => a.discountPrice - b.discountPrice);

  // Initialize variables to track the best combination
  let bestCombination: {
    itemsSelected: Item[];
    totalPrice: number;
    totalWeight: number;
  } | null = null;

  // Function to recursively find the best combination
  function findCombination(
    index: number,
    currentWeight: number,
    currentPrice: number,
    currentItems: Item[]
  ) {
    // Base case: if current weight meets or exceeds desired weight
    if (currentWeight >= desiredWeightInGrams) {
      if (
        !bestCombination ||
        currentPrice < bestCombination.totalPrice ||
        (currentPrice === bestCombination.totalPrice &&
          currentWeight < bestCombination.totalWeight)
      ) {
        bestCombination = {
          itemsSelected: [...currentItems],
          totalPrice: currentPrice,
          totalWeight: currentWeight,
        };
      }
      return;
    }

    // If all items have been considered
    if (index >= processedItems.length) {
      return;
    }

    const item = processedItems[index];

    // Calculate the maximum number of units we might need of this item
    const maxUnits = Math.ceil(
      (desiredWeightInGrams - currentWeight) / item.itemWeightInGrams
    );

    // Try adding different quantities of the current item
    for (let units = 0; units <= maxUnits; units++) {
      const newWeight = currentWeight + units * item.itemWeightInGrams;
      const newPrice = currentPrice + units * item.discountPrice;
      const newItems = currentItems.concat(Array(units).fill(item));

      // Recurse to the next item
      findCombination(index + 1, newWeight, newPrice, newItems);
    }
  }

  // Start the recursive search
  findCombination(0, 0, 0, []);

  if (bestCombination) {
    let cheapestCombination: CheapestItemCombinationForStore = {
      storeName: store,
      itemsSelected: bestCombination.itemsSelected,
      price: bestCombination.totalPrice,
    };
    return cheapestCombination;
  } else {
    throw new Error("Unable to find a valid combination");
  }
}

function createNullCheapestCombination(
  storeName: string
): CheapestItemCombinationForStore {
  return {
    storeName: storeName,
    itemsSelected: null,
    price: null,
  };
}
