import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { NavBar } from "./components/Navbar";
import { ShoppingListProvider } from "./components/ShoppingList";
import useAuth from "./hooks/useAuth";
import ItemGroupsPage from "./pages/itemPage/ItemGroupsPage";
import { Login } from "./pages/login/login";
import { Recipe } from "./pages/recipePage/recipe";
import { Register } from "./pages/register/register";
import { ShoppingList } from "./pages/shoppingList/shoppingList";
import { Unauthorized } from "./pages/unauthorized/unauthorized";

function App() {
  const user = useAuth();

  return (
    <ShoppingListProvider>
      <Router>
        <div className="flex flex-col h-full">
          {/* Conditional rendering of navbar based on user or not */}
          {user && <NavBar></NavBar>}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/shoppingList"
              element={user ? <ShoppingList /> : <Unauthorized />}
            />
            <Route
              path="/recipe"
              element={user ? <Recipe /> : <Unauthorized />}
            />
            <Route
              path="/items"
              element={user ? <ItemGroupsPage /> : <Unauthorized />}
            />
          </Routes>
        </div>
      </Router>
    </ShoppingListProvider>
  );
}

export default App;
